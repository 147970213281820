import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  QuestionCard,
  QuestionText,
} from './StyledComponents';
import TimeSlotsWidget from './TimeSlotsWidget';
import {days, times} from './Helpers';
const mobile = window.innerWidth <= 768;

export const ScheduleQuestion = ({questionObj, index, setResponse, response}) => {

  const [adding, setAdding] = useState(null);
  const [addDay, setAddDay] = useState("");
  const [beginTime, setBeginTime] = useState("");
  const [endTime, setEndTime] = useState("");



  const handleOptionClick = (day) => {
       setAdding(day) 
    }

  const onAddTimeSlots = () => {
    setAdding(true);
  }
 
  const notEmpty= (obj) => {
   if(obj)
     if(Object.keys(obj).length > 0)
       return true;
   return false;
  }

  const handleDeleteTS = (day,ix) => {
      let thisResponse = {};
      if(response){
         thisResponse = response
         let tslots = response[day].split(',');
         if(tslots.length > 1){
           thisResponse[day] = tslots.filter((e,tix)=>tix!=ix).join(',');
          
         }
         else {
          delete thisResponse[day];
         }
          setResponse(index,thisResponse);
      }
     
  }

  const finalizeTimeSlots = (endTime2) => {
       let timeslots = "";
       let thisResponse = {};
       if(response){
         thisResponse = response
         if(response[addDay])
           timeslots = response[addDay];
       }
       if(timeslots != "")
         timeslots = timeslots + ','
       timeslots = timeslots+`${beginTime}-${endTime2}`
       thisResponse[addDay] = timeslots;
       setResponse(index,thisResponse);
       setAdding(false);
       setAddDay("");
       setBeginTime("");
       setEndTime("");
    }


  return (
     <QuestionCard key={index}>
          <QuestionText>{index+1 + ")  "+questionObj.question}</QuestionText>
          <div style={{marginBottom:'10px'}}>
           <TimeSlotsWidget tsData={response} handleDelete={handleDeleteTS}/> 
           {notEmpty(response) && <br/>}
          </div>
           {!adding && <span style={{border:'1px solid black', color:'blue',backgroundColor:'#d8def2',padding:'10px',fontSize:'11pt',cursor:'pointer', borderRadius:'14px'}} onClick={e=>onAddTimeSlots()}> 
             + Add Time Slot  
           </span>}


           {(adding && addDay=="") && 
            <div style={{border:'1px solid #d1e0e0',padding:'10px',borderRadius:'10px',backgroundColor:'#f0f5f5',marginLeft:mobile ? '0px' : '100px',marginRight: mobile ? '0px' : '100px'}}>
              <select value={addDay} onChange={e=>setAddDay(e.target.value)}>
                 <option value="" disabled hidden> Choose Day</option>
   
                {
                 days.map((option) => 
                 (
                     <option 
		       key={questionObj.question+option}
                       value={option} 
                       onClick={e=>handleOptionClick(option)}
                     > 
                         {option}  
                     </option> 
                 ))
                }
              </select>
           </div>
         } 

         {(adding && addDay!="" ) &&  
            <div style={{border:'1px solid #d1e0e0',padding:'10px',borderRadius:'10px',backgroundColor:'#f0f5f5',marginLeft:mobile ? '0px' : '60px',marginRight: mobile ? '0px' : '60px'}}>
           <span onClick={{}} style={{color:'#23a126'}}> <b> {addDay} </b> <br/> </span>
           <span>  Start Time {': ' + beginTime}</span>
            {beginTime=="" ?  "" : <span> <br/></span>} 
               <select value={beginTime=="" ? beginTime : endTime} onChange={beginTime=="" ? e=>setBeginTime(e.target.value) : e=>finalizeTimeSlots(e.target.value)}>
                 <option value="" disabled hidden>Choose {beginTime=="" ? 'Start':'End'} Time</option>
   
                {
                 times.filter((time,index)=>times.indexOf(beginTime) < index).map((option) => 
                 (
                     <option 
                       value={option} 
                       onClick={e=>{}}
                     > 
                         {option}  
                     </option> 
                 ))
                }
              </select>
           
         </div>
         } 

    </QuestionCard>
  );
};
