import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import {
  Container,
  QuestionCard,
  Title,
  QuestionText,
  OptionsContainer,
  OptionButton
} from './StyledComponents';


const options = ['Disagree Strongly', 'Disagree', 'Neutral', 'Agree', 'Agree Strongly'];

export const AgreeQuestion = ({question,index,setResponse,response, mobile}) => {
  //const [response, setResponse] = useState(resp || '');

  const handleOptionClick = (option) => {
    setResponse(index,option)
    }

  return (
        <QuestionCard key={index}>
          <QuestionText>{index+1 + ")  "+question}</QuestionText>
          <OptionsContainer>
            {options.map(option => (
              <OptionButton
                style={{fontSize: mobile ? '8px':'14px',margin: mobile ? '1px':'3px', padding: mobile ? "5px 3px" : "5px 10px"}}
                key={question+option}
                selected={response === option}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </OptionButton>
            ))}
          </OptionsContainer>
        </QuestionCard>
  );
};
