import React, { useState } from 'react';
const mobile = window.innerWidth <= 768;


function TimeSlotsWidget({tsData,handleDelete}) {

  return (
    <div> {tsData ? 
      Object.keys(tsData).map(day=>{ 
       return (
          <div style={{border:'1px solid black',borderRadius:'15px',backgroundColor:'#dcdcde',margin:'15px',padding:'5px',fontSize:'14px',marginLeft: mobile ? '0px' : '100px',marginRight:mobile ? '0px' : '100px'}}> 

            <span style={{padding:'3px',borderRadius:'10px',fontWeight:'bold',color:'#1b1475',textAlign:'left'}}> 
              {day}  <br/>
            </span>
            {tsData[day].split(',').length > 0 ? tsData[day].split(',').map((ts,i)=>(
              <div style={{fontSize:'13px'}}>
                <span onClick={e=>handleDelete(day,i)} style={{cursor:'pointer',color:'red'}}> ⓧ  </span>
                 {tsData[day].split(',')[i].split('-')[0]} - {tsData[day].split(',')[i].split('-')[1]} 
              </div>)) : ""}
          </div>
         ) 
      })
      : ""
    } 
    </div>
  );
}

export default TimeSlotsWidget;

