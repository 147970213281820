export const Version = 'v1.0';
export const Questions = [
{
id:'A0',
type:'long',
question:"The Most Important Thing I'd Want a New Person to Know About Me:",
resultCat: 'important_about_me'
},
{
id:'A1',
type:'long',
question:"My Birth Year (YYYY):",
resultCat: 'birth_year',
filter:'integer',
length:4,
},
{
id:'A2',
type:'long',
question:"My Zip Code:",
resultCat: 'zip_code',
filter:'integer',
length:5,
},
{
id:'A3',
type:'select',
question:'My Gender:',
options:[{label:"Female",value:'female'},{label:"Male",value:'male'},{label:"Non-Binary",value:'nonbinary'},{label:'Other',value:'other'},{label:'Prefer Not to Say',value:'not_provided'}],
resultCat : 'gender',
},
{
id:'A4',
type:'long',
question:"2 of My Favorite Movies (Comma Separated):",
resultCat: 'movies',
},
{
id:'A5',
type:'select',
question:'Tobacco Use:',
options:[{label:"No",value:'no'},{label:"Yes",value:'yes'}],
resultCat : 'tobacco_use',
},

{
id:'A6',
type:'select',
question:'My Level of Alcohol Consumption:',
options:[{label:"Never",value:'never'},{label:"Rarely",value:'rarely'},{label:"Occasionally",value:'occasionally'},{label:'Frequently',value:'frequently'}],
resultCat : 'alcohol_use',
},
{
id:'A7',
type:'select',
question:'My Level of Other Drug Usage:',
options:[{label:"Never",value:'never'},{label:"Rarely",value:'rarely'},{label:"Occasionally",value:'ocasionally'},{label:'Frequently',value:'frequently'}],
resultCat : 'drugs_use',
},
{
id:'A8',
type:'select',
question:'My Sexuality:',
options:[{label:"Asexual",value:'asexual'},{label:"Bisexual",value:'bisexual'},{label:"Heterosexual",value:'heterosexual'},{label:"Homosexual",value:'homosexual'},{label:'Other',value:'other'},{label:'Prefer Not to Say',value:'not_provided'}],
resultCat: 'sexuality',
},
{
id:'A9',
type:'select',
question:'My Relationship Status:',
options:[{label:"Single / Dating",value:'single'},{label:"Commited Partner",value:'partner'},{label:"Married",value:'married'}],
resultCat: 'relationship_status',
},
{
id:'A10',
type:'select',
question:'Have/Going To Have Young Children:',
options:[{label:"No",value:'none'},{label:"Pregnant / Partner Pregnant",value:'pregnant'},{label:"Yes - Youngest Under 5",value:'under5'},{label:'Yes - Youngest 5-10',value:'5-10'},{label:'Yes - Youngest 10-18',value:'10-18'}],
resultCat: 'young_children',
},
{
id:'A11',
type:'select',
question:'Level of Importantance of Religion To Me:',
options:[{label:"Not At All Important",value:'not_important'},{label:"Somewhat Important",value:'somewhat_important'},{label:"Important",value:'important'},{label:"Very Important",value:'very_important'}],
resultCat: 'religiosity',
},
{
id:'A12',
type:'select',
question:'The Religion I Identify With:',
options:[{label:"Agnosticism",value:'agnostic'},{label:"Atheism",value:'atheist'},{label:"Buddhism",value:'buddhism'},{label:"Christianity",value:'christian'},{label:'Hinduism',value:'hindu'},{label:'Islam',value:'islamic'},{label:'Judaism',value:'jewish'},{label:'Sikhism',value:'sikh'},{label:'Taoism',value:'taoist'},{label:'Other',value:'other'}],
resultCat: 'religion',
},
{
id:'A13',
type:'select',
question:'My Level of Health Consciousness:',
options:[{label:"Not At All Health Conscious",value:'not_hc'},{label:"Somewhat Health Conscious",value:'somewhat_hc'},{label:"Health Conscious",value:'hc'},{label:"Very Health Conscious",value:'very_hc'}],
resultCat: 'health_consc',
},
{
id:'A14',
type:'select',
question:'My Level of Athleticism:',
options:[{label:"Not At All Athletic",value:'not_athletic'},{label:"Somewhat Athletic",value:'somewhat_athletic'},{label:"Athletic",value:'athletic'},{label:"Very Athletic",value:'very_athletic'}],
resultCat: 'athleticism',
},
{
id:'A15',
type:'agree',
reverse:true,
question:"I am Reserved",
resultCat: 'personality_extr',
},
{
id:'A16',
type:'agree',
reverse:false,
question:"People See Me as a Natural Leader",
resultCat: 'personality_narc',
},
{
id:'A17',
type:'agree',
reverse:true,
question:"I Hate Being the Center of Attention",
resultCat: 'personality_narc',
},
{
id:'A18',
type:'agree',
reverse:false,
question:"Many Group Activities Tend to be Dull Without Me",
resultCat: 'personality_narc',
},

{
id:'A19',
type:'agree',
reverse:true,
question:"I Have Never Gotten Into Trouble With The Law",
resultCat: 'personality_psych',
},


{
id:'A20',
type:'agree',
reverse:false,
question:"It's Not Wise To Tell Your Secrets",
resultCat: 'personality_mach',
},
{
id:'A21',
type:'agree',
reverse:true,
question:"I Feel Embarassed if Someone Compliments Me",
resultCat: 'personality_narc',
},

{
id:'A22',
type:'agree',
reverse:false,
question:"I Like to use Clever Manipulation to Get My Way",
resultCat: 'personality_mach',
},
{
id:'A23',
type:'agree',
reverse:false,
question:"Make Sure Your Plans Benefit Yourself, Not Others",
resultCat: 'personality_mach',
},
{
id:'A24',
type:'agree',
reverse:false,
question:"It's True That I Can be Mean to Others",
resultCat: 'personality_psych',
},

{
id:'A25',
type:'agree',
reverse:false,
question:"I am Generally Trusting",
resultCat: 'personality_agree',
},
{
id:'A26',
type:'agree',
reverse:false,
question:"People Often Say I'm Out of Control",
resultCat: 'personality_psych',
},

{
id:'A27',
type:'agree',
reverse:true,
question:"I'm an Average Person",
resultCat: 'personality_narc',
},
{
id:'A28',
type:'agree',
reverse:true,
question:"I Avoid Dangerous Situations",
resultCat: 'personality_psych',
},

{
id:'A29',
type:'agree',
reverse:true,
question:"I Tend to be Lazy",
resultCat: 'personality_consc',
},
{
id:'A30',
type:'agree',
reverse:false,
question:"I Like to Get Revenge on Authorities",
resultCat: 'personality_psych',
},

{
id:'A31',
type:'agree',
reverse:true,
question:"I am Relaxed and Handle Stress Well",
resultCat: 'personality_neur',
},
{
id:'A32',
type:'agree',
reverse:false,
question:"Whatever it Takes, You Must Get Important People On Your Side",
resultCat: 'personality_mach',
},
{
id:'A33',
type:'agree',
reverse:false,
question:"There are Things You Should Hide From Others to Preserve Your Reputation",
resultCat: 'personality_mach',
},

{
id:'A34',
type:'agree',
reverse:true,
question:"I Do Not Have Many Artistic Interests",
resultCat: 'personality_open',
},
{
id:'A35',
type:'agree',
reverse:false,
question:"I am Outgoing and Sociable",
resultCat: 'personality_extr',
},
{
id:'A36',
type:'agree',
reverse:false,
question:"It's Important to Get Revenge and Time it Correctly",
resultCat: 'personality_mach',
},

{
id:'A37',
type:'agree',
reverse:true,
question:"I Tend to Find Fault With Others",
resultCat: 'personality_agree',
},
{
id:'A38',
type:'agree',
reverse:false,
question:"I Usually do a Thorough Job on My Work",
resultCat: 'personality_consc',
},
{
id:'A39',
type:'agree',
reverse:false,
question:"It's Wise to Keep Track of Information That You Can Use Against People Later",
resultCat: 'personality_mach',
},

{
id:'A40',
type:'agree',
reverse:false,
question:"I Get Nervous Easily",
resultCat: 'personality_neur',
},
{
id:'A41',
type:'agree',
reverse:false,
question:"Most People Can Be Manipulated",
resultCat: 'personality_mach',
},
{
id:'A42',
type:'agree',
reverse:false,
question:"I Insist on Getting the Respect I Deserve",
resultCat: 'personality_narc',
},
{
id:'A43',
type:'agree',
reverse:false,
question:"Paypack Needs to be Quick and Nasty",
resultCat: 'personality_psych',
},
{
id:'A44',
type:'agree',
reverse:false,
question:"I Enjoy Having Sex With People I Hardly Know",
resultCat: 'personality_psych',
},

{
id:'A45',
type:'agree',
reverse:false,
question:"I Have Been Compared to Famous People",
resultCat: 'personality_narc',
},


{
id:'A46',
type:'agree',
reverse:false,
question:"I Have an Active Imagination",
resultCat: 'personality_open',
},
{
id:'A47',
type:'agree',
reverse:false,
question:"People Who Mess With me Always Regret It",
resultCat: 'personality_psych',
},


{
id:'A48',
type:'agree',
reverse:false,
question:"I Like to Get Acquainted With Important People",
resultCat: 'personality_narc',
},

{
id:'A49',
type:'agree',
reverse:false,
question:"Avoid Direct Conflict With Others Because They May be Useful in the Future",
resultCat: 'personality_mach',
},
{
id:'A50',
type:'agree',
reverse:false,
question:"I Know I'm Special Because Everyone Tells Me So",
resultCat: 'personality_narc',
},
{
id:'A51',
type:'agree',
reverse:false,
question:"I'll Say Anything to get What I Want",
resultCat: 'personality_psych',
},
{
id:'A52',
type:'long',
question:"Activities I Would Enjoy Doing With a Friend Regularly (Comma Separated):",
resultCat: 'activities',
},
{
id:'A53',
type:'schedule',
question:"Time Slots I'm Usually Free:",
resultCat: 'availableTimes',
},
{
id:'A54',
type:'upload',
question:"A Recent Photo of Me:",
resultCat: 'photo',
},
{
id:'A55',
type:'long',
question:"Most Number Miles Away I Want To Search:",
resultCat: 'miles_thresh',
filter:'integer',
length:4,
},
{
id:'A56',
type:'select',
question:`Would You Like To Optimize For Proximity or Personality Match?`,
options:[{label:"Proximity",value:'proximity'},{label:"Personality",value:'personality'}],
resultCat : 'optimization',
},

]


