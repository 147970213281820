import React, { useState, useEffect } from 'react';
import './App.css';
import { Fade } from "react-awesome-reveal";
import {Questionnaire} from './components/Questionnaire';
import Login from './components/Login';
import styled, { createGlobalStyle } from 'styled-components';
import { signOut } from "firebase/auth";
import Logo  from './assets/friendzonefull.png';
import Logosm  from './assets/logowhite.png';
import { collection, query, doc, setDoc, getDocs, getDoc, where } from "firebase/firestore"; 
import { getDownloadURL, ref } from "firebase/storage";
import Matches from './components/Matches'; 
import feedbackImg from './assets/feedback.png';
import {getState, getIntersection, days, times, dayDifference, getDate} from './components/Helpers';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Roboto', sans-serif;
    background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
`;



const mobile = window.innerWidth <= 768;

const App = () => {
    const [page, setPage] = useState('survey')
    const [mainData, setMainData] = useState({})

/*    const [user, setUser] = useState(null);
    const [storedResponses, setStoredResponses] = useState([])
    const [userData, setUserData] = useState(null)
    const [matches, setMatches] = useState([]);
    const [db, setDb] = useState(null);
    const [auth, setAuth] = useState(null);  
    const [storage, setStorage] = useState(null);*/


  const setMainField = (field, value) => {
     let newData = {...mainData};
     newData[field] = value;
     setMainData(newData); 
  }

  const getMatches = (db, user, storage)=> {
   let results = [];
   const matchesRef = collection(db, "results", "NC", "matches");
   const q = query(matchesRef, where("members", "array-contains", user.uid));
   return getDocs(q).then((querySnapshot)=>{
   if (!querySnapshot.empty) {
       let promises = [];
       querySnapshot.forEach(doc => {
           let docData = doc.data();
           docData['matchId'] = doc.id;
           let daysPassed = dayDifference(getDate(),docData['date']) 
           let myAttr = docData['info1']['uid'] == user.uid ? 'info1' : 'info2';
           if( (!docData[myAttr]['rating'] && (docData['info1']['accepted'] && docData['info2']['accepted'])) || ((!docData['info1']['accepted'] || !docData['info2']['accepted']) && daysPassed < 30)){
           let uid = docData.members.filter(tuid=>tuid != user.uid)[0]
           promises.push(getDownloadURL(ref(storage,`userPhotos/${uid}/pic.jpg`)).then(link=> { 
               docData['imgLink'] = link;
               return docData;
           })
           )}
      });
    return Promise.all(promises).then((results)=>{
      return results;
    })
    }
  });
 }
  

  const getMyAttr = (i,attr) => {
    if(mainData.matches[i]['info1']['uid'] == mainData.user.uid)
      return mainData.matches[i]['info1'][attr]
    return mainData.matches[i]['info2'][attr]
  }

  const getMatchAttr = (i,attr) => {
    if(mainData.matches[i]['info1']['uid'] != mainData.user.uid)
      return mainData.matches[i]['info1'][attr]
    return mainData.matches[i]['info2'][attr]
  }


  const matchAndNotAccepted = () => {
   if(!mainData.matches) 
     return 0;

   let result = 0;
   for(let i=0; i < mainData.matches.length; i++)
      if (!getMyAttr(i,"accepted") || (!getMyAttr(i,"rating") && getMatchAttr(i,"accepted")))
        result+=1;
   return result;
  }

  const pageChanged = async (newPage,db,user,storage) => {
     if(db) {
        let newMatches = await getMatches(db,user,storage);
        setMainField('matches',newMatches);
     }
     setPage(newPage)
  }

  const onLogin = async (user,auth,db, storage,firstLogin=false,userData={}) =>   {
    let matches = [];
    let storedResponses = {};
    let  data = {...userData}
    if(firstLogin){
      await setDoc(doc(db, "userData", user['uid']), userData);
    }
    else{
      let myDoc = await getDoc(doc(db, "userData", user['uid']));
      if (myDoc.exists()) {
        data = myDoc.data()
        matches = await getMatches(db,user,storage);
      }
    }
    if(data['state'] && !firstLogin) {
       const surveyRef = doc(db, `results/${data['state']}/surveyResults`, user['uid']);
            const docSnap = await getDoc(surveyRef);
            if (docSnap.exists()) {
                storedResponses = docSnap.data();
            } 
    }
    setMainData({storage,db,auth,user,storedResponses,matches : matches ?? [],'userData':data});

  }

  const logout = () => {
    signOut(mainData['auth']).then(function() {
      setMainData({... mainData, user:null, userData:null});
      console.log('Signed Out');
    }, function(error) {
       console.error('Sign Out Error', error);
    });
  }


    return (<div style={{width:"100%"}}>
      {mainData.user == null && <Login onLogin={onLogin} logout={logout}/>}
      <GlobalStyle/>
      {mainData.user && 
      (

      <div style={{fontFamily:'Plus Jakarta Sans',fontSize:'12pt'}}>
       <div className="sidebar" style={{width:mobile ? "100px" : "250px"}}>
      <center> <img src={mobile ? Logosm : Logo} width={mobile ? '50px' : '200px'} /> </center>
      <ul>
        <li onClick={e=>{setPage('survey')}}> Survey</li>
        <li onClick={e=>{setPage('matches')}}>
          <div class="notification-icon">
           Matches
           { matchAndNotAccepted() > 0  && 
             <div class="badge"> { matchAndNotAccepted() }</div>
           }
           </div>
        </li>
      </ul>
      <div style={{marginTop:'400px'}}>  <center> <img style={{borderRadius:'10px'}} width={mobile ? "100px" : "200px"} src={feedbackImg}/> </center> </div>
    </div>
    <div style={{width:'100vw',height:'100vh',marginTop:'25px',marginLeft: mobile ? '100px': '250px'}}>
       {page == "survey" && (
        <center> <Questionnaire user={mainData.user} auth={mainData.auth} db={mainData.db} storage={mainData.storage}  mobile={mobile} pageChanged={pageChanged} storedResponses={mainData.storedResponses} /> </center>
       )}
       {page == "matches" && (
        <center> <Matches matches={mainData.matches}  setMainField={setMainField} user={mainData.user} auth={mainData.auth} db={mainData.db} storage={mainData.storage}  mobile={mobile} storedResponses={mainData.storedResponses} userData={mainData.userData}/> </center>
       )}


     </div> 
     </div>)}
    </div>
    )
  }

export default App;
