import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  QuestionCard,
  QuestionText,
} from './StyledComponents';
import UploadWidget from './UploadWidget';


export const UploadQuestion = ({questionObj, index, setResponse, response, preview, image, setImage, setPreview, handleImageChange}) => {


  return (
     <QuestionCard key={index}>
          <QuestionText>{index+1 + ")  "+questionObj.question}</QuestionText>
          <div style={{marginBottom:'10px'}}>
            <UploadWidget setResponse={setResponse} response={response} preview={preview} image={image} setImage={setImage} setPreview={setPreview} handleImageChange={handleImageChange}/>
         </div>
    </QuestionCard>
  );
};
