import React, { useState } from 'react';

import styled from 'styled-components';
import FZLogo from '../assets/friendzonefull.png';
import Spinner from 'react-bootstrap/Spinner';
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { sendEmailVerification, getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import EmailVerification from './EmailVerification';
import { getStorage, ref, uploadBytes } from "firebase/storage";

const tosLink = "https://friendzone.best/legal/terms.html";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAcY3dtXhGNBS2VAZkzHZqXZlRvD2DLajY",
  authDomain: "friendzone-best.firebaseapp.com",
  projectId: "friendzone-best",
  storageBucket: "friendzone-best.appspot.com",
  messagingSenderId: "154226124082",
  appId: "1:154226124082:web:3d7d106981a85a77a4232b",
  measurementId: "G-T9WBEMGP27"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage();
 
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /Going To Haveheight: 100vh;
  background-color: #f0f2f5;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  background: white;
  padding: 2rem;
  width:400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Input = styled.input`
  margin-bottom: 1rem;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const LinkButton = styled.button`
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  margin-top: 0.5rem;
  text-decoration: underline;

  &:hover {
    color: #0056b3;
  }
`;

const ErrorMessage = styled.p`
  color: red;
  margin-bottom: 1rem;
`;

const Login = ({onLogin}) => {
  const [accepted, setAccepted] = useState(false);
  const [registering, setRegistering] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loggingIn, setLoggingIn] = useState(null);
  const [user, setUser] = useState(null);
  const [name, setName] = useState("");
  const [page, setPage] = useState("home");

  const handleLogin = async (e) => {
    e.preventDefault();
   
const tosLink = "https://friendzone.best/legal/terms.html";
 try {
      setLoggingIn(true);
      setError(null);
      let creds = await signInWithEmailAndPassword(auth,email, password);
      setUser(creds['user']);
      setLoggingIn(false);
    } catch (error) {
      setError(error.message);
      setLoggingIn(false);
    }
  };
  const cleanError = (error) => {
    let result = error;
    let cleans = ["Firebase",":","(","auth",")","."];
    let spaces = ["/","-"]
    for (let i=0; i< cleans.length;i++)
      result = result.replaceAll(cleans[i],"");
    for (let i=0; i< spaces.length;i++)
      result = result.replaceAll(spaces[i]," ");
    return result;
  }
  const handleRegistering = async (e) => {
    e.preventDefault();
    setError(null);
    setRegistering(!registering);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      setError(null);
      await createUserWithEmailAndPassword(auth, email, password)
      let creds = await signInWithEmailAndPassword(auth,email, password);
      window.gtag('event', 'conversion', {
          'send_to': 'AW-16569246807/YlQLCNeQzrAZENfI6tw9',
          'value': 1.0,
          'currency': 'USD'
      });

     
      await sendEmailVerification(auth.currentUser);
      setUser(creds['user']);
    } catch (error) {
      setError(error.message);
    }
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    try {
      await sendPasswordResetEmail(auth, email);
      alert('Password reset email sent!');
    } catch (error) {
      setError(error.message);
    }
  };
  if (user && !user.emailVerified) {
    return <EmailVerification name={name} user={user} auth={auth} storage={storage} db={db} onLogin={onLogin}/>;
  }
  if (user && user.emailVerified){
    onLogin(user,auth,db,storage)
  }
  return (
    <Container>
      <div style={{marginBottom:'30px'}}> <center> <img src={FZLogo} width='290px'/> </center></div>
      <Form onSubmit={registering ? handleRegister : handleLogin}>
        <h2>{registering ? "Register" : "Login"}</h2>
{registering &&  <Input
          type="text"
          placeholder="First Name / Nickname"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />}

        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <Input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        {registering && <div> 
        <Input
          onClick={e=>setAccepted(!accepted)}
          type="checkbox"
          checked={accepted}
          required
        /> I Accept The <a href={tosLink} target="_blank"> Terms & Conditions </a>
        </div>}
        {error && <ErrorMessage>{cleanError(error)}</ErrorMessage>}
	{loggingIn ?     (<center> <Spinner animation="border" variant="primary" /> </center>)  : <Button type="submit">{registering ? "Register" : "Login"} </Button>}
        <LinkButton onClick={handleRegistering}>{registering ? "Login" : "Register"}</LinkButton>
        <LinkButton onClick={handleForgotPassword}>Forgot Password</LinkButton>
      </Form>
    </Container>
  );
};

export default Login;
