import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  Container,
  QuestionCard,
  Title,
  QuestionText,
} from './StyledComponents';

export const DropDownQuestion = ({question, index, options, response, setResponse}) => {

  const handleOptionClick = (index,optionValue) => {
    setResponse(index,optionValue);
  };


  return (
        <QuestionCard key={index}>
          <QuestionText>{index+1 + ")  "+question}</QuestionText>

           <select value={response} onChange={e=>setResponse(index,e.target.value)}>
              <option value="" disabled hidden>Choose Value</option>

             {
              options.map((option) => 
              (
                  <option 
		    key={question+option.value}
                    value={option.value} 
                    onClick={e=>handleOptionClick(index,option.value)}
                  > 
                      {option.label}  
                  </option> 
              ))
             }
           </select>
        </QuestionCard>
  );
};
