import React, { useEffect, useState } from 'react';
const mobile = window.innerWidth <= 768;
import { collection, query, where, getDoc, getDocs, doc,updateDoc } from "firebase/firestore";
import { notifyAPI }  from "./APIHelpers";
import { getDownloadURL, ref } from "firebase/storage";
import {getIntersection, days, times, dayDifference, getDate} from './Helpers';
const getToken = async (user) => { let token = await user.getIdToken(); console.log(token)}
import Spinner from 'react-bootstrap/Spinner';
import Button from 'react-bootstrap/Button';  

function Matches({matches, setMainField, user,db, storage, userData}) {
  const n = matches?.length ?? 0;
  const [acceptings, setAcceptings] = useState(new Array(n).fill(false));
  const [ratings, setRatings] = useState(new Array(n).fill(-1));
  const [submittingRatings, setSubmittingRatings] = useState(new Array(n).fill(false));
  const [ratingErrors, setRatingErrors] = useState(new Array(n).fill(""));
  const [ratingReasons, setRatingReasons] = useState(new Array(n).fill(""));
 
  const setSubmittingRating = (i,submittingRating) => {
     let newSubmittingRatings = [... submittingRatings];
     newSubmittingRatings[i] = submittingRating;
     setSubmittingRatings(newSubmittingRatings);
  }

  const setAccepting = (i,accepting) => {
     let newAcceptings = [... acceptings];
     newAcceptings[i] = accepting;
     setAcceptings(newAcceptings);
  }


  const setRatingError = (i,err) => {
     let newErrors = [... ratingErrors];
     newErrors[i] = err;
     setRatingErrors(newErrors);
  }

  const setRatingReason = (i,ratingReason) => {
     let newRatingReasons = [... ratingReasons];
     newRatingReasons[i] = ratingReason;
     setRatingReasons(newRatingReasons)
  }
 
  const setRating = (i, rating) => {
     let newRatings = [...ratings];
     newRatings[i] = rating;
     setRatings(newRatings);
  }

  const getMyAttr = (i,attr) => {
    if(matches[i]['info1']['uid'] == user.uid)
      return matches[i]['info1'][attr]
    return matches[i]['info2'][attr]
  }


  const getMatchAttr = (i,attr) => {
    if(matches[i]['info1']['uid'] != user.uid)
      return matches[i]['info1'][attr]
    return matches[i]['info2'][attr]
  }

  //Given e.g. a time range of 10:00 AM-11:00 AM, expand it into an array like [10:00 AM, 10:30 AM, 11:00 AM]
  const expandTR = (timeRange) => {
    let trSplit = timeRange.split('-');
    let start = times.indexOf(trSplit[0])
    let end = times.indexOf(trSplit[1]);
    return times.slice(start,end+1)
  }

  const hasTimeMatch = (day,trange,mySchedule) => {
    if(mySchedule[day]){
      let theirSet = new Set(expandTR(trange));
      let myTRs = mySchedule[day].split(',');
      for(let i=0;i<myTRs.length;i++){
         let tr = myTRs[i]
         let mySet = new Set(expandTR(tr));
         if(getIntersection(theirSet,mySet).size > 0)
           return true;
      }
    }
    return false;
  }




  const submitRating = async (i) => {
    if(!ratingReasons[i] || ratingReasons[i]?.length<1 || ratings[i] <1){
      setRatingError(i,"Please Rating and Reason");
      return false;
    }
    setRatingError(i,"")
    setSubmittingRating(i,true);
    try {
       let attr = 'info1';
       let myInfo = matches[i][attr];
       if(myInfo['uid'] != user.uid){
         attr = 'info2';
        myInfo = matches[i][attr];
       }
       myInfo['rating'] = ratings[i];
       myInfo['ratingReason'] = ratingReasons[i]; 
       const docRef = doc(db, "results", "NC", "matches",matches[i]['matchId']);
       let myDoc = {};
       myDoc[attr] = myInfo;
       await updateDoc(docRef,myDoc);
       let newDoc = {... matches[i]};
       newDoc[attr] = myInfo;
       let nMatches = [... matches];
       nMatches[i] = newDoc;
       setMainField('matches',nMatches)
     } catch(e) {console.log(e);};

       setSubmittingRating(i,false);
  }

  const onAccept = async (i) => {
    setAccepting(i,true); 
    const docRef = doc(db, "results", "NC", "matches",matches[i]['matchId']);
    let attr = 'info1';
    let myInfo = matches[i][attr];
    let matchUID = matches[i]['info2']['uid'];
    if(myInfo['uid'] != user.uid){
      attr = 'info2';
      myInfo = matches[i][attr];
      matchUID = matches[i]['info1']['uid'];
    }
    myInfo['accepted'] = true;
    myInfo['email'] = user.email;
    myInfo['name'] = userData['name'];
    let myDoc = {};
    myDoc[attr] = myInfo;
    await updateDoc(docRef,myDoc);
    await notifyAPI(user,matchUID);
    let newDoc = await getDoc(docRef);
    setAccepting(i,false);
    let nMatches = [... matches];
    nMatches[i] = newDoc.data();
    setMainField('matches',nMatches)
  }
 
  const acceptStatus = (i) => {
    if(i < matches.length)
      if(getMatchAttr(i,'accepted'))
       return(
       <div>
           <div style={{marginTop:'30px',backgroundColor:'#d4d4d4',padding:'10px',borderRadius:'10px',border:'1px solid #919191'}}>
             ✅ Match Has Accepted: <br/>
              Name: {getMatchAttr(i,'name')} <br/>
              E-Mail: {getMatchAttr(i,'email')} <br/>
           </div>
           {getMyAttr(i,'rating') ? <div><center> <br/>Thank You For Submitting Your Rating!</center> </div>  : <div style={{marginTop:'30px',borderRadius:'10px',border:'1px solid #919191',padding:'5px'}}> 
              If you meet...<br/> <span style={{color:'blue'}}><b> Please let us know how it went!</b><br/> (It's Private) </span> <br/><br/>
              Match Quality: {[0,1,2,3,4].map(
                r=>(
                  <span style={{cursor:'pointer',color:'#d1ca2a'}} onClick={e=>setRating(i,r+1)}> 
                     {ratings[i] > r ? `★` : `☆` }
                  </span>
                ))}
          <center>  <input placeholder="Please Explain Rating" value={ratingReasons[i]} onChange={e=>{setRatingReason(i,e.target.value)}}/> <br/><br/>
           {ratingErrors[i] != "" && <div style={{marginTop:'3px',fontSize:'8pt',color:'red'}}>{ratingErrors[i]} </div>}
           {submittingRatings[i] ? <Spinner variant="primary"/> :  <Button onClick={e=>submitRating(i)}> Submit Rating </Button>}</center> <br/>
           </div>}

       </div>)
      else
        return(
         <div style={{marginTop:'30px'}}> 
           <span style={{backgroundColor:'#d4d4d4',padding:'10px',borderRadius:'10px',border:'1px solid #919191'}}>
             {`🕒 Waiting For Response From Match. This Match Expires in ${Math.max(0,30-dayDifference(getDate(),matches[i]['date']))} days`}
           </span>
        </div>)
      
  }

  const showSchedules = (i) => {
    let result = [(<><h3>Schedule</h3></>)]
    let matchSchedule = getMatchAttr(i,'schedule');
    let mySchedule = getMyAttr(i,'schedule');

    let msDays = Object.keys(matchSchedule);
    for(let i = 0; i<days.length;i++){
       let day = days[i];
       if(msDays.indexOf(day) >= 0) {
          result.push((<><h5> <u> <b> {day} </b></u> </h5></>))
          let timeRanges = matchSchedule[day].split(',');
          for(let j = 0; j < timeRanges.length; j++){
             let trange = timeRanges[j];
             let hasMatch = hasTimeMatch(day,trange,mySchedule);
             result.push((<><span style={{fontWeight: hasMatch ? "bold" : "normal", color: hasMatch ? "green" : "black" }}> {trange.toString()} </span> <br/> </>))
          }
          result.push((<><br/></>));
       }
    }
    return result;
  }


  return (
    <div style={{backgroundColor:'white',maxWidth:mobile ? '250px' : '600px', width:'fit-content',padding:'40px',boxShadow:"0 6px 20px rgba(0, 0, 0, 0.1);", borderRadius:'15px'}} onClick={e=>getToken(user)}>    
      {matches.map((mtch,i)=> 
         <div style={{paddingBottom:'30px'}}>
           <center>
              <h2> Match {`#${i+1}`} {`(${(1-matches[i]['score'])*100.0}%)`} </h2> <br/> 
              <img src={matches[i]['imgLink']} width="260px"/> <br/><br/>
              <span> <b> Important to know: </b>  {`${getMatchAttr(i,'important')}`} </span>  <br/>
              <b> Enjoys Activities: </b>  {`${getMatchAttr(i,'activities')}`} <br/> <br/>
              {showSchedules(i)}
            {getMyAttr(i,'accepted')  ? acceptStatus(i) : 
               acceptings[i] ?  (<center> <Spinner animation="border" variant="primary" /> </center>) : <Button onClick={e=>onAccept(i)}> Accept Match (Share E-Mail) </Button>}
           </center>
         <div style={{paddingTop:'30px'}}> </div>
         <hr/>
         </div> 

     )}
     { (!matches || matches?.length == 0) &&
         <div> <span> No New Matches Yet, Check Back Later! </span> </div>
     }
    </div>

  );
}

export default Matches;

