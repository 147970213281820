import React, { useState } from 'react';
import './StepWidget.css';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';


function StepWidget({content,numSteps,currentStep,handlePrev,handleNext, handleSubmit, submitting, submitError, mobile}) {

  return (
    <div className="widget-container" style={{maxWidth : mobile ? "280px" : "800px"}}>
      <div className="header" style={{width:'100%'}}>

        <h2>{currentStep >= 0 ? `Survey Page ${currentStep+1}/${numSteps}` : ''}</h2>
      </div>
       {currentStep >= 0 &&  <div className="progress-indicators">
           {Array.from(Array(numSteps)).map((step, index) => (
            <div className={`circle ${index <= currentStep ? 'filled' : ''}`} key={`step${index}${currentStep}`}></div>
           ))}
         </div> }


      <div>
          {content}
      </div>
      {currentStep >= 0 &&  <div className="progress-indicators">
           {Array.from(Array(numSteps)).map((step, index) => (
            <div className={`circle ${index <= currentStep ? 'filled' : ''}`} key={`bstep${index}${currentStep}`}></div>
           ))}
         </div> 
      }
      <div className="footer">
        {currentStep >=0 ? <button id="circbutton" onClick={handlePrev} disabled={currentStep < 1}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
          </svg>
        </button> : <div></div>}
        {submitError !="" && <div style={{fontSize:'8pt',color:'red',margin:'15px'}}> {submitError} </div>}
        {currentStep != numSteps - 1 ?  (<button id="circbutton" onClick={handleNext}>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
            <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
          </svg>
        </button>) :
		submitting ? <Spinner  animation="border" variant="primary" /> : <Button variant="primary" onClick={e=>handleSubmit()}> Submit Survey</Button>}
      </div>
    </div>
  );
}

export default StepWidget;

