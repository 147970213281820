import styled from 'styled-components';

const mobile = window.innerWidth <= 768;


export const Container = styled.div`
  margin-top:50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  background: #ffffff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  width: 90%;
  max-width: 840px;
  transition: all 0.3s ease-in-out;

  &:hover {
    box-shadow: 0 15px 45px rgba(0, 0, 0, 0.15);
  }
`;

export const Title = styled.h1`
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
  font-weight: 700;
`;

export const QuestionCard = styled.div`
  max-width: ${mobile ? "240px" : "700px"};
  background: #ffffff;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  margin: 20px 0;
  padding: 20px;
  width: 100%;
  transition: transform 0.3s ease-in-out;
  
  &:hover {
    transform: translateY(-5px);
  }
`;

export const QuestionText = styled.h3`
  margin: 0 0 20px;
  font-weight: 500;
  color: #555;
  font-size: 18px;
`;

export const OptionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width:100%;
`;

export const OptionButton = styled.button`
  background: ${props => (props.selected ? '#007BFF' : '#ffffff')};
  color: ${props => (props.selected ? '#ffffff' : '#007BFF')};
  border: 2px solid #007BFF;
  border-radius: 30px;
  padding: 5px 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: ${props => (props.selected ? '0 6px 12px rgba(0, 123, 255, 0.2)' : 'none')};

  &:hover {
    background: #0056b3;
    color: #ffffff;
    transform: translateY(-3px);
  }
`;


