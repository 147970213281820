import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import EmailImg from '../assets/email.png';
import { sendEmailVerification } from "firebase/auth";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f2f5;
`;

const Message = styled.p`
  margin-bottom: 1rem;
  padding-left:50px;
  padding-right:50px;
`;

const Button = styled.button`
  padding: 0.75rem;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  margin-bottom: 1rem;

  &:hover {
    background-color: #0056b3;
  }
`;

const EmailVerification = ({ name, user, auth, storage, db, onLogin }) => {
  const [isVerified, setIsVerified] = useState(user.emailVerified);
  const [initialEmailSent, setInitialEmailSent] = useState(false);

  useEffect(() => {
    const checkEmailVerification = setInterval(async () => {
      await user.reload();
      const currentUser = auth.currentUser;
      if (currentUser.emailVerified) {
        setIsVerified(true);
        clearInterval(checkEmailVerification);
      }
    }, 5000); // Check every 5 seconds

    return () => clearInterval(checkEmailVerification);
  }, [user]);

  const sendVerificationEmail = async () => {
    try {
      await sendEmailVerification(auth.currentUser);
      alert('Verification email sent!');
    } catch (error) {
      alert(error.message);
    }
  };

  if (isVerified) {
    onLogin(user,auth,db, storage,true,{name:name, email:user.email});
  }
  return (
    <Container>
      <center> <img src={EmailImg} width="180px" /> </center> <br/><br/>
      <Message> Please Check Your E-Mail And Click The Link To Verify Your Account </Message>
      <Button onClick={sendVerificationEmail}>Resend Verification Email</Button>
    </Container>
  );
};

export default EmailVerification;

