import React, { useState } from 'react';
import {AgreeQuestion} from './AgreeQuestion'; 
import {DropDownQuestion} from './DropDownQuestion'; 
import {ScheduleQuestion} from './ScheduleQuestion'; 
import {UploadQuestion} from './UploadQuestion'; 
import {LongFormQuestion} from './LongFormQuestion'; 
import {Container, Title} from './StyledComponents';
import {Questions, Version} from './Questions';
import StepWidget from './StepWidget';
import Step1Img from '../assets/step1.png';
import { doc, setDoc, updateDoc } from "firebase/firestore"; 
import { ref, uploadBytes } from "firebase/storage";
import Check from '../assets/checkmark.png';
import {getState} from './Helpers';
import {findMatchesAPI} from './APIHelpers';

export const Questionnaire = ({user,auth,db,storage, mobile, pageChanged, storedResponses={}}) => {
  const [responses, setResponses] = useState({version:Version, ...storedResponses});
  const [currentStep, setCurrentStep] = useState(-1);
  const [image, setImage] = useState(null);
  const [preview, setPreview] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState("");
  const [submitted, setSubmitted] = useState(false);
 
  const sleep = (ms) => {
     return new Promise(resolve => setTimeout(resolve, ms));
  } 

  const validateResponses = () => {
   let error = "";
   for(let i=0; i < Questions.length; i++){
    if (!responses[i] && Questions[i]['type'] != 'upload' ){
       if (error=="")
         error="Need Response For Question:";
       error = error + ` ${i+1},`
    }
    if(!image)
      error="Need Recent Photo.";
   }
   setSubmitError(error.substring(0,error.length-1))
   return error == "";
  }

  const handleSubmit = async () => {
    setSubmitError("");
    setSubmitting(true);
    try {
       if(validateResponses()){
         await setDoc(doc(db, `results/${getState(responses[2])}/surveyResults`, user['uid']), responses);
         await updateDoc(doc(db,'userData',user['uid']),{'state':getState(responses[2])});
         const storageRef = ref(storage, `userPhotos/${user.uid}/pic.jpg`);
         // 'file' comes from the Blob or File API
         uploadBytes(storageRef, image).then((snapshot) => {
           console.log('file upload successful.');
         });
        let matched = null;
        try{
          matched = await findMatchesAPI(user);
        }catch(error){
          console.log(error);
        }
        setSubmitted(true);

        console.log('matched',matched)

        if(matched)
        if(matched['result']){
           pageChanged('matches',db,user,storage);
        }
       }
    } catch (error) {
      console.log(error);
      setSubmitError(error.message);
    }

 
    setSubmitting(false);
  }

  const handleImageChange = (e) => {
    if(e == null){
       setImage(null);
       setPreview(null);
    }
    else{
       const file = e.target.files[0];
       if (file) {
         setImage(file);
         const reader = new FileReader();
         reader.onloadend = () => {
           setPreview(reader.result);
         };
         reader.readAsDataURL(file);
       }
      } 
  };

  const itemsPerPage = 15;
  const numSteps = Math.ceil(Questions.length/itemsPerPage);

  const setResponse = (i,resp) => {
    let newResponses = {};
    Object.assign(newResponses,responses); 
    newResponses[i] = resp;
    setResponses(newResponses);
    console.log(newResponses);
    //console.log('aaaa',storedResponses);
  }


  const handleNext = () => {
    if (currentStep < numSteps - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };


  const getContent = (start_i, end_i) => {
    if(start_i < 0)
    return (
<div>
<h4><b> Welcome to The FriendZone Survey! </b></h4> 
      <div className="content-layout" style={{paddingTop:'15x'}}>
       {Object.keys(storedResponses).length == 0  && <div> <img src={Step1Img} className="step-image"/></div>}
       {(Object.keys(storedResponses).length > 0 && !mobile) && <div> <img src={Check} className="step-image"/></div>}
       {Object.keys(storedResponses).length == 0 && <div>  This Survey Contains <b> {Questions.length} Questions </b> <br/> <br/> <b> Avg Completion Time:</b> <i> Less Than 20 Minutes </i> <br/> <br/> <b><u>Remember</u></b>: There Are No Correct Answers, and Being Honest Helps Improve Our Algorithms</div>}
       {Object.keys(storedResponses).length > 0 && <div>  <b> We have <u> already received</u> your survey submission</b>, and you should receive a friend match in the coming weeks <br/> <br/> You may modify your responses if you'd like<br/><br/>  <b><u>Remember</u></b>: There Are No Correct Answers, and Being Honest Helps Improve Our Algorithms</div>}
      </div>
</div>
     )

    return Questions.map((q,index) => {
     if(index >= start_i && index < end_i){
      if(q.type == 'agree') return <AgreeQuestion question={q.question} index={index} setResponse={setResponse} response={responses[index]} mobile={mobile}/>
      if(q.type == 'select') return <DropDownQuestion question={q.question} index={index} options={q.options} setResponse={setResponse} response={responses[index] ? responses[index] : ""}/>
      if(q.type == 'long') return <LongFormQuestion questionObj={q} index={index} setResponse={setResponse} response={responses[index]}/>
      if(q.type == 'schedule') return <ScheduleQuestion questionObj={q} index={index} setResponse={setResponse} response={responses[index]}/>
      if(q.type == 'upload') return <UploadQuestion questionObj={q} index={index} setResponse={setResponse} response={responses[index]} preview={preview} image={image} setImage={setImage} setPreview={setPreview} handleImageChange={handleImageChange}/>

     }
    })

  }

  return (
   <div style={{paddingBottom:'60px'}}>
    <Container style={{maxWidth: mobile ? '310px' : '840px'}}>
    <Title> 🤝 FriendZone Survey 📝</Title>
    {!submitted && <StepWidget content={getContent(currentStep*itemsPerPage,(currentStep+1)*itemsPerPage)} numSteps={numSteps} currentStep={currentStep} handlePrev={handlePrev} handleNext={handleNext} handleSubmit={handleSubmit} submitting={submitting} submitError={submitError} mobile={mobile}/>}
    {submitted && <div> 
          <img src={Check} width='100px'/><br/><br/>
          <h2> Your Results Have Been Submitted! </h2><br/>
          <h4> We will notify you via e-mail <br/> when a match is found. Please check your spam inboxes <br/>  every once in a while, as <br/> Google and Microsoft seem to be flagging our E-Mails! </h4>
     </div>}
    </Container>
   </div>
  );
};

;
