import React, { useState } from 'react';

const UploadWidget = ({image,setImage, preview, setPreview, handleImageChange}) => {

  return (
    <div>
        {!image ? <div style={{backgroundColor:'#e8e8e8',borderRadius:'15px',marginLeft:'20px',marginRight:'20px',padding:'5px'}}> 
         <input type="file" accept="image/*" onChange={handleImageChange} /> 
        </div> : <div style={{color:'red'}} onClick={e=>handleImageChange(null)}> ⓧ  Remove </div>} 
           {preview && (<div style={{fontSize:'10pt',fontWeight:'bold'}}> <img src={preview} alt="Preview" style={{ height: '150px'}}/> <br/> {image['name']} </div>) }
        <br/> 
    </div>
  );
};

export default UploadWidget;
