import React, { useState } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import {
  QuestionCard,
  QuestionText,
} from './StyledComponents';



export const LongFormQuestion = ({questionObj, index, setResponse, response}) => {

  const handleTextChange = (newText) => {
      console.log(newText)
      if(questionObj.filter == 'integer'){
        if(/[0-9]+$/.test(newText) || newText=="") 
          setResponse(index,newText)
      }
      else {
        setResponse(index,newText);
      }
        
    }



  return (
     <QuestionCard key={`qc${index}${questionObj.question}`}>
          <QuestionText>{index+1 + ")  "+questionObj.question}</QuestionText>
            <input type="text" style={{width:"100%"}} onChange={e=>handleTextChange(e.target.value)} value={response || ""} maxLength={questionObj.length || 100} size={questionObj.length || 100}/>
    </QuestionCard>
  );
};
