const restServer = "https://matching-bmcyml7opq-uc.a.run.app"


//given a problem_description return all the dates and corrective actions for it
export async function findMatchesAPI(user) {
  let token = await user.getIdToken();
  let url = `${restServer}`
  let data = {'reqType': 'findMatches'};

  return await fetch(url, {
      body: JSON.stringify(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : token,
      }
    }).then(res => res.json()).catch(function(error) {

       console.log(error.toString());
    }).then(res => { 
         console.log(res);
         return res
    });
}

//given a problem_description return all the dates and corrective actions for it
export async function notifyAPI(user,notifyUID) {
  let token = await user.getIdToken();
  let url = `${restServer}`
  let data = {'reqType': 'notifyAccept', 'notifyUID' : notifyUID};

  return await fetch(url, {
      body: JSON.stringify(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization' : token,
      }
    }).then(res => res.json()).catch(function(error) {

       console.log(error.toString());
    }).then(res => { 
         console.log(res);
         return res
    });
}
//given a problem_description return all the dates and corrective actions for it
export async function signupAPI(data) {
  console.log('a');
  let url = `${restServer}/submit-form`
  //url+= "?n="+n.toString()
  //url+= "&acn="+acn.toString()

  return await fetch(url, {
      body: JSON.stringify(data),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //'x-access-token' : token,
      }
    }).then(res => res.json()).catch(function(error) {

       console.log('b');
       console.log(error.toString());
    }).then(res => { 
         console.log(res);
         return res
    });
}
